<script>
import Layout from "@/router/layouts/main";
import { api } from "@/state/services";
import { mapState } from "vuex";
import { required } from "vuelidate/lib/validators";

export default {
  locales: {
    pt: {
    },
    es: {
    },
  },
  components: {
    Layout,
  },
  computed: {
    ...mapState({
      account: (state) => state.account,
    }),
  },
  data() {
    return {
      alert: {
        pix: { type: "", message: "" },
      },

      loading: false,

      pix: {
        type: null,
        key: null,
        types: {
          "": "–",
          cpf: "CPF",
          cnpj: "CNPJ",
          cellphone: "Celular",
          email: "E-mail",
          random: "Chave Aleatória",
        },
      },
    };
  },
  validations: {
    pix: {
      type: { required },
      key: { required },
    },
  },
  methods: {
    getPix() {
      api.get("user/pix").then((response) => {
        if (response.data.status == "success") {
          this.pix.type = response.data.type;
          this.pix.key = response.data.key;
        }
      });
    },
    pixSubmit() {
      this.loading = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.loading = false;
        return;
      } else {
        const { type, key } = this.pix;
        if (type && key) {
          api
            .post("user/pix", {
              key: this.pix.key,
              type: this.pix.type,
            })
            .then((response) => {
              if (response.data.status == "success") {
                this.$v.$reset();

                this.alert.pix.type = "alert-success";
                this.alert.pix.message = "A chave PIX foi salva com sucesso!";
              } else {
                this.alert.pix.type = "alert-danger";
                this.alert.pix.message = response.data.message;
              }

              this.loading = false;
            })
            .catch((error) => {
              if (error) {
                this.loading = false;
              }
            });
        }
      }
    },
  },
  mounted() {
    this.getPix();
  },
  watch: {
    "pix.type": function (v, o) {
      if (o && v != o) {
        this.pix.key = "";
      }
    },
  },
};
</script>

<template>
  <Layout>
    <div class="row">
      <div class="col-xl-7">
        <div class="page-title-box d-flex justify-content-between">
          <h4 class="mb-0 font-size-18">{{ t("Meu PIX") }}</h4>
        </div>
        <div v-if="alert.pix.message" :class="'alert ' + alert.pix.type">
          {{ t(alert.pix.message) }}
        </div>
        <div class="card">
          <div class="card-body table-responsive">
            <b-form class="p-3" @submit.prevent="pixSubmit">
              <img src="@/assets/images/payments/logo-pix.png" class="mb-4" style="height: 50px" />
              <b-form-group :label="t('Type')">
                <select v-model="pix.type" class="custom-select mb-2">
                  <option v-for="(pix, index) in pix.types" :key="index" :value="index">
                    {{ pix }}
                  </option>
                </select>
                <div v-if="$v.pix.type.$error" class="invalid-feedback">
                  <span v-if="!$v.pix.type.required">{{
                    t("The type of the key is mandatory.")
                    }}</span>
                </div>
              </b-form-group>
              <template v-if="pix.type == 'cpf'">
                <b-form-group :label="t('CPF')">
                  <b-form-input v-model="pix.key" type="text" :class="{ 'is-invalid': $v.pix.key.$error }" v-mask="'###.###.###-##'"></b-form-input>
                  <div v-if="$v.pix.key.$error" class="invalid-feedback">
                    <span v-if="!$v.pix.key.required">{{
                      t("The key is required.")
                      }}</span>
                  </div>
                </b-form-group>
              </template>
              <template v-else-if="pix.type == 'cnpj'">
                <b-form-group :label="t('CNPJ')">
                  <b-form-input v-model="pix.key" type="text" :class="{ 'is-invalid': $v.pix.key.$error }" v-mask="'##.###.###/####-##'"></b-form-input>
                  <div v-if="$v.pix.key.$error" class="invalid-feedback">
                    <span v-if="!$v.pix.key.required">{{
                      t("The key is required.")
                      }}</span>
                  </div>
                </b-form-group>
              </template>
              <template v-else-if="pix.type == 'cellphone'">
                <b-form-group :label="t('Celular')">
                  <b-form-input v-model="pix.key" type="text" :class="{ 'is-invalid': $v.pix.key.$error }" v-mask="'(##) #####-####'"></b-form-input>
                  <div v-if="$v.pix.key.$error" class="invalid-feedback">
                    <span v-if="!$v.pix.key.required">{{
                      t("The key is required.")
                      }}</span>
                  </div>
                </b-form-group>
              </template>
              <template v-else-if="pix.type == 'email'">
                <b-form-group :label="t('E-mail')">
                  <b-form-input v-model="pix.key" type="text" :class="{ 'is-invalid': $v.pix.key.$error }" v-mask="undefined
                    "></b-form-input>
                  <div v-if="$v.pix.key.$error" class="invalid-feedback">
                    <span v-if="!$v.pix.key.required">{{
                      t("The key is required.")
                      }}</span>
                  </div>
                </b-form-group>
              </template>
              <template v-else-if="pix.type == 'random'">
                <b-form-group :label="t('Chave Aleatória')">
                  <b-form-input v-model="pix.key" type="text" :class="{ 'is-invalid': $v.pix.key.$error }" v-mask="'XXXXXXXX-XXXX-XXXX-XXXX-XXXXXXXXXXXX'"></b-form-input>
                  <div v-if="$v.pix.key.$error" class="invalid-feedback">
                    <span v-if="!$v.pix.key.required">{{
                      t("The key is required.")
                      }}</span>
                  </div>
                </b-form-group>
              </template>
              <div class="mt-4">
                <b-button :disabled="this.loading == true" type="submit" variant="default">
                  {{ t("Save") }}
                  <b-spinner v-if="loading" small class="ml-2 align-middle" variant="white" role="status"></b-spinner>
                </b-button>
              </div>
            </b-form>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
